<template>
  <n-grid :x-gap="12" :y-gap="12" :cols="4" layout-shift-disabled>
        <n-gi>
            <HeartComponent></HeartComponent>
        </n-gi>
        <n-gi :span="2">
            <div class="date">
            <p>我们在一起已经{{ days }}天了！</p>
        </div>
        </n-gi>
        <n-gi>
            <HeartComponent></HeartComponent>
        </n-gi>
  </n-grid>
  <n-grid :x-gap="12" :y-gap="12" :cols="4" layout-shift-disabled>
        <n-gi :span="4">
            <FireWorkComponent class="firework"></FireWorkComponent>
        </n-gi>
  </n-grid>
  <n-grid :x-gap="12" :y-gap="12" :cols="4" layout-shift-disabled>
        <n-gi>
          <div class="light-green" />
        </n-gi>
        <n-gi :span="2">
          <div>
            <n-timeline>
            <n-timeline-item content="Oops" />
            <!-- <n-timeline-item
              type="success"
              title="Waiting....."
              content="半夜约会"
              time="2023.05.20"
            /> -->
            <n-timeline-item
              type="success"
              title="Waiting....."
              content="半夜约会"
              time="2023.05.20"
            />
            <n-timeline-item
              type="success"
              title="Waiting....."
              content="看爱在系列电影"
              time="2023.05.21"
            />
            <n-timeline-item
              type="success"
              title="Waiting....."
              content="一起在家吃火锅"
              time="2023.05.27"
            />
            <n-timeline-item
              type="success"
              title="Waiting....."
              content="一起坐摩天轮"
              time="2023.05.27"
            />
            <n-timeline-item
              type="success"
              title="Waiting....."
              content="一起看夕阳落下"
              time="2023.05.27"
            />
            <n-timeline-item
              type="success"
              title="Waiting....."
              content="来大姨妈了周六的饭我包了"
              time="2023.05.31"
            />
            <n-timeline-item
              type="success"
              title="Waiting....."
              content="一起看了电影天空之城"
              time="2023.06.03"
            />
            <n-timeline-item
              type="success"
              title="Waiting....."
              content="红烧排骨成功"
              time="2023.06.03"
            />
            <n-timeline-item
              type="success"
              title="Waiting....."
              content="Waiting....."
              time="2023.06.03"
            />
            <!-- <n-timeline-item
              type="error"
              content="Waiting....."
              time="2018-04-03 20:46"
            />
            <n-timeline-item
              type="warning"
              title="Waiting....."
              content="Waiting....."
              time="2018-04-03 20:46"
            />
            <n-timeline-item
              type="info"
              title="Waiting....."
              content="Waiting....."
              time="2018-04-03 20:46"
              line-type="dashed"
            /> -->
            <n-timeline-item content="Oops" />
          </n-timeline>
        </div>
        </n-gi>
        <n-gi>
          <div class="light-green" />
        </n-gi>
  </n-grid>
</template>

<script>

import HeartComponent from './HeartComponent.vue'

import FireWorkComponent from './FireWorkComponent.vue'

import { NGrid, NGi, NTimeline, NTimelineItem } from 'naive-ui'

export default {
    name: 'DateComponent',
    props: {
        // msg: String
    },
    components: {
        HeartComponent,
        FireWorkComponent,
        NGrid,
        NGi,
        NTimeline,
        NTimelineItem
    },
    setup() {
      return {
      }
    },
    data() {
        return {
          form: {
            date: '2023-02-04'
          },
          days: null
        }
    },
    methods: {
    },
    mounted() {
        console.log("mounted")
        const today = new Date()
        const selectedDate = new Date(this.form.date)
        const timeDiff = Math.abs(selectedDate.getTime() - today.getTime())
        const days = Math.floor(timeDiff / (1000 * 3600 * 24))
        this.days = days
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

div {
    color: white;
}

.firework {
    position: absolute;
    top: 0;
    left: 0;
/*    background-color: #000;*/
    z-index: -1;
}

.date {
  font-size: xxx-large;
}

</style>
