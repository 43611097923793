import { createApp } from 'vue'
import App from './App.vue'
// import naive from 'naive-ui'

import 'naive-ui/lib/styles.js'

// General Font
import 'vfonts/Lato.css'
// Monospace Font
import 'vfonts/FiraCode.css'
// createApp(App).mount('#app')


import { createRenderer } from '@vue/runtime-core'

const renderer = createRenderer({
  createElement(type) {
    if (type === 'canvas') {
      return document.createElement('canvas')
    }
  },
  patchProp(el, key, prevValue, nextValue) {
    if (key === 'width' || key === 'height') {
      el.setAttribute(key, nextValue)
    } else {
      el[key] = nextValue
    }
  }
})

const app = createApp(App, {
  strict: false
})

app.config.isCustomElement = tag => tag === 'canvas'
app.use(renderer)
// app.use(naive)
app.mount('#app')