<template>
  <Icon size="48">
    <Heart class="heart"/>
  </Icon>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { Heart } from '@vicons/ionicons5'
import { Icon } from '@vicons/utils'
export default defineComponent({
  name: 'HeartComponent',
  components: {
    Icon,
    Heart
  },
  setup() {
    const isHeartShown = ref(true)

    setInterval(() => {
      isHeartShown.value = !isHeartShown.value
    }, 500)

    return { isHeartShown }
  },
})
</script>

<style>
.heart {
  color: #f44336;
  animation: blink 1s infinite;
  display: inline-block;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
</style>