<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <!-- <n-config-provider :theme="theme">
    <DateComponent></DateComponent>
  </n-config-provider> -->
  <n-config-provider :theme="darkTheme" :locale="zhCN" :date-locale="dateZhCN" :theme-overrides="themeOverrides">
    <n-space vertical>
      <!-- <n-input />
      <n-date-picker /> -->
      <DateComponent></DateComponent>
    </n-space>
  </n-config-provider>
</template>

<!-- <template>
  <n-config-provider :theme="darkTheme" :locale="zhCN" :date-locale="dateZhCN">
    <n-space vertical>
      <n-input />
      <n-date-picker />
    </n-space>
  </n-config-provider>
</template> -->

<!-- <script lang="ts">
import DateComponent from './components/DateComponent.vue'
import { defineComponent, ref } from 'vue'
import { darkTheme } from 'naive-ui'
import { GlobalTheme } from 'naive-ui'

export default defineComponent({
  name: 'App',
  components: {
    DateComponent
  },
  setup () {
    return {
      darkTheme,
      theme: ref<GlobalTheme | null>(null)
    }
  }
})
</script> -->

<script>
  import DateComponent from './components/DateComponent.vue'
  import { defineComponent } from 'vue'
  // import { NConfigProvider, NInput, NDatePicker, NSpace } from 'naive-ui'
  import { NConfigProvider, NSpace } from 'naive-ui'

  // theme
  import { createTheme, inputDark, datePickerDark } from 'naive-ui'
  // locale & dateLocale
  import { zhCN, dateZhCN } from 'naive-ui'
  // import { GlobalThemeOverrides } from 'naive-ui'

  /**
   * js 文件下使用这个做类型提示
   * @type import('naive-ui').GlobalThemeOverrides
   */
  const themeOverrides = {
    common: {
      primaryColor: '#FF0000'
    },
    // Button: {
    //   textColor: '#FF0000'
    // },
    // Select: {
    //   peers: {
    //     InternalSelection: {
    //       textColor: '#FF0000'
    //     }
    //   }
    // }
    // ...
  }

  export default defineComponent({
    components: {
      NConfigProvider,
      // NInput,
      // NDatePicker,
      NSpace,
      DateComponent,
      // GlobalThemeOverrides
    },
    setup() {
      return {
        darkTheme: createTheme([inputDark, datePickerDark]),
        zhCN,
        dateZhCN,
        themeOverrides
      }
    }
  })
</script>


<!-- 
<style>
  body {
    background: black;
  }
</style> -->

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
body {
    background: black;
}
</style>
