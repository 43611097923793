<template>
  <canvas ref="canvasRef"  />
</template>

<script>
import { ref, onMounted } from 'vue'
// import Canvas from 'canvas'
  
export default {
  name: 'FireWorkComponent',
  data() {

  },
  methods: {
  },
  mounted() {
    const canvas = this.$refs.canvasRef;
    const ctx = canvas.getContext("2d");
    const width = (canvas.width = window.innerWidth);
    const height = (canvas.height = window.innerHeight);

    function random(min, max) {
          return Math.random() * (max - min) + min;
        }

        // function distance(x1, y1, x2, y2) {
        //   return Math.hypot(x2 - x1, y2 - y1);
        // }

        const fireworks = [];

        function createFirework() {
          const particleCount = 500;
          const hue = random(0, 360);
          const startX = random(0, width);
          const startY = height;

          let particles = [];
          for (let i = 0; i < particleCount; i++) {
            particles.push({
              x: startX,
              y: startY,
              vx: random(-5, 5),
              vy: random(-15, -5),
              size: random(1, 5),
              color:   'hsl('+hue+', 100%, '+ random(50, 70)+'%)',
              alpha: 1
            });
          }


          fireworks.push(particles);
        }

        function updateFireworks() {
          for (let i = fireworks.length - 1; i >= 0; i--) {
            const particles = fireworks[i];

            for (let j = particles.length - 1; j >= 0; j--) {
              const p = particles[j];

              p.x += p.vx;
              p.y += p.vy;
              p.alpha -= 0.01;
              p.size -= 0.05;

              if (p.alpha <= 0 || p.size <= 0) {
                particles.splice(j, 1);
              }
            }

            if (particles.length === 0) {
              fireworks.splice(i, 1);
            }
          }
        }

        function drawFireworks(ctx) {
          for (let particles of fireworks) {
            for (let p of particles) {
              ctx.beginPath();
              ctx.arc(p.x, p.y, p.size, 0, Math.PI * 2);
              ctx.fillStyle = p.color;
              ctx.globalAlpha = p.alpha;
              ctx.fill();
            }
          }
        }
    
    setInterval(() => {
      createFirework();
    }, 1000);

    function loop() {
      ctx.clearRect(0, 0, width, height);
      updateFireworks();
      drawFireworks(ctx);
      requestAnimationFrame(loop);
    }

    loop();
  },
  setup() {
    const canvasRef = ref(null)

    onMounted(() => {
      
    })

    return {
      canvasRef,
    }
  },
}
</script>